import React from 'react';
import './App.css';
import ChartView from "./chart/chartView";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {Container, Navbar, NavbarBrand} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Disclaimer from "./disclaimer";
import {converter} from "./rates/currencyConverter";

library.add(fas, far);

function App() {
    return (
        <div className={"App"} lang={"TR-tr"}>
            <div className={"content"}>
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <NavbarBrand>
                            {"Dolary"}
                            {"   "}
                            <FontAwesomeIcon icon={["far", "money-bill-alt"]} style={{color: "#82ca9d"}}/>
                        </NavbarBrand>
                    </Container>
                </Navbar>
                <ChartView converter={converter} currencies={["Dollar", "Euro"]}/>
            </div>
            <Disclaimer/>
        </div>
    );
}

export default App;
