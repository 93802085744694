import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Currency, CurrencyOrRate, CurrencyRate, RateSuffix} from "./model";

export interface ChartProps {
    seriesToShow: CurrencyOrRate[];
    data: ChartSeriesData[];
    labels: Labels;
    colors: ColorSet;
}

type Labels = {
    [n in CurrencyOrRate]?: string;
};

type ColorSet = {
    [n in CurrencyOrRate]?: string;
};

export type ChartSeriesData = {
    [v in CurrencyOrRate]?: number;
} & {
    date: string;
};

export default function Chart(props: ChartProps) {
    return (
        <ResponsiveContainer width="95%" height={400} minWidth={100} className={"responsiveChart"}>
            <LineChart
                data={props.data}
                style={{margin: "auto"}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date"/>
                <YAxis label={{value: '₺', position: 'insideLeft'}} yAxisId="left" stroke="#a52f36"/>
                <Line yAxisId="left" type="linear" connectNulls={true} dataKey={"Lira"} stroke="#a52f36"
                      activeDot={{r: 4}}
                      dot={{r: 2}}/>
                {
                    props.seriesToShow
                        .map((c, i) => {
                            const isRate = c.endsWith(RateSuffix);
                            return (
                                <>
                                    <YAxis key={`series-axis_${i}`}
                                           yAxisId={`series_${i}`} label={{
                                        value: props.labels[c],
                                        position: isRate ? undefined : 'insideRight',
                                        stroke: props.colors[c],
                                        fontWeight: "lighter"
                                    }}
                                           orientation="right"
                                           stroke={props.colors[c]}
                                    />
                                    <Line key={`series-line_${i}`}
                                          yAxisId={`series_${i}`}
                                          dataKey={c}
                                          stroke={props.colors[c]}
                                          activeDot={{r: 4}}
                                          dot={{r: 2}}
                                          connectNulls={true}
                                          type="linear"
                                    />
                                </>
                            );
                        })
                }
                <Tooltip
                    formatter={(value: number, key: Currency | CurrencyRate) => {
                        const separator = key.indexOf("_");
                        let label;
                        if (separator > -1) {
                            label = props.labels[key.substr(0, separator) as Currency] + "/₺";
                        } else {
                            label = props.labels[key as Currency];
                        }
                        return [value.toFixed(2), label]
                    }}/>
                <Legend/>
            </LineChart>
        </ResponsiveContainer>
    )
}
