import {Button, Collapse, Form, Table} from "react-bootstrap";
import SalaryEntry from "./salaryEntry";
import {useState} from "react";
import tr from "date-fns/locale/tr";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './salaryDatasetView.css';
import './clickables.css'
import {readIndex, readItem, removeItem, writeItem} from "../storage";
import StoreView from "./storeView";

export interface SalaryDatasetViewProps {
    onSalariesChanged: (salaries: SalaryState[]) => void;
    showStorage?: boolean
}

export interface SalaryState {
    amountText?: string;
    date: Date;
    amount?: number
}

const onBulkImport = (editText: string) => {
    const tokens = editText.split(/\s/).filter(t => !!t);

    let error = false;
    const salaries = tokens.reduce(
        (salaries, current, index, items) => {
            if (!error && index % 2 === 1) {
                const dateText = items[index - 1];
                const dateTokens = dateText.split('.');
                error = dateTokens.length !== 3;
                const date = new Date(Number(dateTokens[2]), Number(dateTokens[1]) - 1, Number(dateTokens[0]), 12);
                error = isNaN(date.getTime());
                if (!error) {
                    const amountText = current.replaceAll(',', '');
                    const amount = parseFloat(amountText);
                    salaries.push({
                        date,
                        amountText,
                        amount
                    });
                }
            }
            return salaries;
        }, [] as SalaryState[]);

    return error ? null : salaries;
}
export default function SalaryDatasetView(props: SalaryDatasetViewProps) {
    const [salaryList, setSalaryList] = useState<SalaryState[]>([{
        date: new Date(),
        amountText: "10000",
        amount: 10000
    }])
    const [savedList, setSavedList] = useState<string[]>([...(Object.keys(readIndex()))])
    const [showEdit, setShowEdit] = useState(false);
    const [editText, setEditText] = useState("");
    const [tooltip, setTooltip] = useState("");
    const [showStorage, setShowStorage] = useState(props.showStorage);
    const onAddNewSalaryItem = () => {
        const lastDate: Date = salaryList.length ? salaryList[salaryList.length - 1].date : new Date();

        setSalaryList([...salaryList, {date: lastDate || new Date()}]);
    };

    const setStateAndNotify = (salaries: SalaryState[]) => {
        setSalaryList(salaries);
        props.onSalariesChanged(salaries.slice());
    }

    return (
        <div>
            <Table hover size="sm" style={{width: "50%", margin: "auto"}}>
                <thead>
                <tr>
                    <th>Tarih</th>
                    <th>Maaş(₺)</th>
                </tr>
                </thead>
                <tbody>
                {
                    salaryList.map((s, i) => {
                        return (<tr key={`salary-row_${i}`}>
                                <SalaryEntry key={i}
                                             salary={salaryList[i].amountText} date={salaryList[i].date}
                                             badSalary={!salaryList[i].amount}
                                             focusDate={false} focusSalary={false}
                                             onDateChange={(d) => {
                                                 const salaries = salaryList.slice();
                                                 salaries[i].date = d;
                                                 setStateAndNotify(salaries)
                                             }}
                                             onSalaryChange={(d, completed = false) => {
                                                 const salaries = salaryList.slice();
                                                 salaries[i].amountText = d;
                                                 let result: number | undefined;

                                                 if (d && d.trim()) {
                                                     const n = Number(d);
                                                     if (n === 0 || n) {
                                                         result = n;
                                                     }
                                                 }
                                                 salaries[i].amount = result;
                                                 setStateAndNotify(salaries)
                                                 if (completed && result && salaries.length - 1 === i) {
                                                     onAddNewSalaryItem();
                                                 }
                                             }}
                                             onRemoveClicked={() => {
                                                 const salaries = salaryList.slice();
                                                 salaries.splice(i, 1);
                                                 setStateAndNotify(salaries);
                                             }}
                                />
                            </tr>
                        );
                    })
                }
                <tr>
                    <td className={"iconpack"} style={{textAlign: "start"}} colSpan={3}>
                        <div className={"iconpack"}>
                            <FontAwesomeIcon className={"clickable"} icon={["far", "plus-square"]}
                                             onClick={onAddNewSalaryItem}
                                             onMouseEnter={() => setTooltip("Yeni satır ekle")}
                                             onMouseLeave={() => setTooltip("")}/>
                            <FontAwesomeIcon className={salaryList.length ? "dangerous" : "disabled-clickable"}
                                             icon={["far", "trash-alt"]}
                                             onClick={() => setStateAndNotify([])}
                                             onMouseEnter={() => setTooltip("Tüm satırları sil")}
                                             onMouseLeave={() => setTooltip("")}/>
                            <FontAwesomeIcon className={"clickable"} icon={["far", "edit"]} onClick={() => {
                                setShowEdit(!showEdit);
                                setEditText(salaryList.reduce((text, current) => {
                                    const dateText = `${current.date.getDate()}.${current.date.getMonth() + 1}.${current.date.getFullYear()}`;
                                    text += dateText + "\t" + current.amountText + "\n";
                                    return text;
                                }, ""))
                            }}
                                             onMouseEnter={() => setTooltip("Düzenle")}
                                             onMouseLeave={() => setTooltip("")}/>
                            <FontAwesomeIcon className={"clickable"} icon={["far", "save"]} onClick={() => {
                                setShowStorage(!showStorage);
                            }}
                                             onMouseEnter={() => setTooltip("Tarayıcıya kaydet")}
                                             onMouseLeave={() => setTooltip("")}/>

                            <span className={"actionTip"}>{tooltip}</span>
                        </div>
                        <div style={{fontSize: "10px"}}>
                            <Collapse in={showEdit}>
                                <div id="example-collapse-text">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Her satıra tarih ve ücret bilgisi girin (Örn: 23.06.2021
                                            2,000.55). <br/> Kuruş için ayırıcı karakter noktadır. Virgül karakteri
                                            ihmal edilir.</Form.Label>
                                        <Form.Control as="textarea" rows={3} style={{fontSize: "10px"}} value={editText}
                                                      onChange={(e) => setEditText(e.target.value)}/>
                                    </Form.Group>

                                    <Button size="sm" style={{padding: "0px 2px"}} variant="outline-secondary"
                                            onClick={() => {
                                                const salaries = onBulkImport(editText);
                                                if (salaries) {
                                                    setShowEdit(false);
                                                    setStateAndNotify([...salaries]);
                                                }
                                            }}>
                                        Kaydet <FontAwesomeIcon style={{fontSize: "14px"}} className={"clickable"}
                                                                icon={["far", "save"]} onClick={() => {
                                        setShowStorage(!showEdit);
                                    }}/>
                                    </Button>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Collapse in={showStorage}>
                                <div>
                                    <span style={{color: "crimson", fontWeight: "bold", fontSize: "9px"}}>* Bilgileriniz yalnızca bu bilgisayardaki tarayıcınıza kaydedilir.</span>
                                    <div className={"storageGroup"}>
                                        <StoreView onSave={(name: string) => {
                                            writeItem(salaryList, name);
                                            setSavedList([...(Object.keys(readIndex()))]);
                                        }} onLoad={(name: string) => {
                                            const item = readItem<SalaryState[]>(name);
                                            item && setStateAndNotify(item);
                                        }} onDelete={(name: string) => {
                                            removeItem(name);
                                            setSavedList([...(Object.keys(readIndex()))]);
                                        }} savedNames={savedList}/>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>
    )
}
