import {Form} from "react-bootstrap";
import {CurrencyOrRate} from "./model";

export function CurrencySelector({
                                     selectables,
                                     selections,
                                     onChange
                                 }: { selectables: CurrencyOrRate[], selections: CurrencyOrRate[], onChange: (name: CurrencyOrRate, checked: boolean) => void }) {
    return (
        <Form>
            {
                selectables.map(selectable =>
                    <Form.Check
                        key={`id_${selectable}`}
                        inline
                        label={selectable}
                        name="group1"
                        type="checkbox"
                        id={`id_${selectable}`}
                        checked={selections.indexOf(selectable) > -1}
                        onChange={(event) => onChange(selectable, event.target.checked)}
                    />
                )
            }
        </Form>
    );
}
