const indexName = "dataIndex";
const itemPrefix = "dataset_"

function dateReviver(key: string, value: any) {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
    }

    return value;
}

export const readIndex = (): Record<string, string> => {
    const item = localStorage.getItem(indexName);

    return item ? JSON.parse(item, dateReviver) : {};
}

const writeIndex = (index: Record<string, string>): void => {
    localStorage.setItem(indexName, JSON.stringify(index, dateReviver, 2));
}

const itemName = (name: string): string => itemPrefix + name;

export const writeItem = <T>(t: T, name: string): void => {
    const index = readIndex();
    index[name] = itemName(name);
    writeIndex(index);
    localStorage.setItem(index[name] , JSON.stringify(t));
}

export const readItem = <T>(name: string): T | null => {
    const index = readIndex();
    const item = localStorage.getItem(index[name]);
    return item ? JSON.parse(item, dateReviver) : null;
}

export const removeItem = (name: string) => {
    const index = readIndex();
    localStorage.removeItem(index[name]);
    delete index[name];
    writeIndex(index);
}
