import ratesRaw from "./rates.json";
import {Currency} from "../chart/model";

type Code = "TP_DK_USD_A" | "TP_DK_EUR_A";


export interface CurrencyConverter {
    convert: (from: Currency, to: Currency, amount: number, date: Date) => number;
    getRate: (from: Currency, to: Currency, date: Date) => number;
}

const toCodeMap: {[name in Currency]: Code} = {
    Dollar: "TP_DK_USD_A",
    Euro: "TP_DK_EUR_A",
    Lira: "TP_DK_USD_A"
}

const toDateText = (date: Date): string => date.getFullYear() + "-" + (date.getMonth() + 1);
export const converter: CurrencyConverter = {
    convert: (from, to, amount, date) => {
        const code = toCodeMap[to];
        const dateText = toDateText(date);

        console.log("DateText", dateText);
        const item = ratesRaw.items.find((i) => i.Tarih === dateText && i[code]);
        return item ? amount / parseFloat(to === "Dollar" ? item.TP_DK_USD_A! : item.TP_DK_EUR_A!) : Number.NaN;
    },
    getRate: (from, to, date) => {
        const code = toCodeMap[to];
        const dateText = toDateText(date);
        const item = ratesRaw.items.find((i) => i.Tarih === dateText && i[code]);
        return item ? parseFloat(item[code]!) : Number.NaN;
    }
}
