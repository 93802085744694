import "./disclaimer.css"

export default function Disclaimer() {
    return (
        <footer className={"footer"}>
            <div>
                <p>
                    <span style={{fontWeight: "bold"}}>Girilen maaş bilgileri tarayıcınızda kalır, herhangi bir şekilde bir
                        sunucuya gönderilmez.</span>
                    <br/>Bilgi amaçlıdır, suya sabuna dokunulmaz.</p>
                <p>İlla gerekiyorsa: <a href={"mailto:admin@sen.kim"}>admin@sen.kim</a></p>
            </div>
        </footer>
    );
}
