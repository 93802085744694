import SalaryDatasetView, {SalaryState} from "./salaryDatasetView";
import {useState} from "react";
import Chart, {ChartSeriesData} from "./chart";
import {Currency, CurrencyOrRate, CurrencyRate, RateSuffix, toRate} from "./model";
import {CurrencySelector} from "./currencySelector";
import {CurrencyConverter} from "../rates/currencyConverter";

type Salary = Omit<SalaryState, "amountText">;

export interface ChartViewProps {
    currencies: Currency[]
    converter: CurrencyConverter;
}

export default function ChartView(props: ChartViewProps) {
    const [data, setData] = useState<ChartSeriesData[]>([]);

    const currenciesAndRates: CurrencyOrRate[] = [...props.currencies, ...(props.currencies.map(c => (c + RateSuffix) as CurrencyRate))].sort();

    const [visibleCurrencies, setVisibleCurrencies] = useState<(Currency | CurrencyRate)[]>([currenciesAndRates[0]]);


    const calculate = (salaries: Salary[]) => {
        salaries.sort((a, b) => a.date.getTime() - b.date.getTime());

        const interpolate = (start: Date, end: Date, salary?: number): Salary[] => {
            const res: Salary[] = [];
            for (let year = start.getFullYear(); year <= end.getFullYear(); year++) {
                const startMonth = (year === start.getFullYear()) ? start.getMonth() + 1 : 0;
                const endMonth = year === end.getFullYear() ? end.getMonth() : 12;
                for (let month = startMonth; month < endMonth; month++) {
                    res.push({date: new Date(year, month), amount: salary})
                }
            }
            return res;
        }

        const interpolatedSalaries = salaries.reduce((extendedSalaries, salary, index) => {
            if (index > 0) {
                const interpolations = interpolate(salaries[index - 1].date, salary.date, salaries[index - 1].amount);
                extendedSalaries.push(...interpolations);
            }
            extendedSalaries.push(salary);
            return extendedSalaries;
        }, [] as Salary[]);

        const salaryData = interpolatedSalaries.map<ChartSeriesData>(data => {
            const output: ChartSeriesData = {
                date: `${data.date.getFullYear()}-${data.date.getMonth() + 1}`,
                Lira: data.amount,
            }

            props.currencies.forEach(currency => {
                output[currency] = data.amount && props.converter.convert("Lira", currency, data.amount!, data.date);
                output[toRate(currency)] = props.converter.getRate("Lira", currency, data.date);
            });

            return output;
        });

        setData(salaryData);
    };

    const seriesSelectionChange = (value: CurrencyOrRate, checked: boolean) => {
        setVisibleCurrencies(vc => checked ? [...vc, value].sort() : vc.filter(c => c !== value));
    }

    let params = new URLSearchParams(window.location.search);

    return (
        <>
            <div style={{paddingTop: "20px"}}>
                <Chart data={data} seriesToShow={visibleCurrencies}
                       labels={{Dollar: "$", Lira: "₺", Euro: "€", Euro_Rate: "€/₺", Dollar_Rate: "$/₺"}}
                       colors={{
                           Dollar: "#4e795e",
                           Euro: "blue",
                           Lira: "#a52f36",
                           Dollar_Rate: "#c1e5ce",
                           Euro_Rate: "lightblue"
                       }}/>
                <CurrencySelector selectables={currenciesAndRates} selections={visibleCurrencies}
                                  onChange={seriesSelectionChange}/>
            </div>
            <div>
                <span style={{color: "red", fontWeight: "bold", fontSize: "9px"}}>* Girilen maaş bilgileri tarayıcınızda kalır, herhangi bir şekilde bir
                        sunucuya gönderilmez.</span>
            </div>
            <SalaryDatasetView showStorage={!!params.get("storage")}
                onSalariesChanged={(salaries => calculate(salaries.filter(s => s.amount) as Required<SalaryState>[]))}/>
        </>
    );
}
