import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";

import './storeView.css';
import './clickables.css'

export interface StoreViewProps {
    onSave: (name: string) => void;
    onLoad: (name: string) => void;
    onDelete: (name: string) => void;
    savedNames: string[];
}

export default function StoreView(props: StoreViewProps) {
    const [saveName, setSaveName] = useState("");
    return (
        <>
            <div id="example-collapse-text">
                <table style={{margin: "auto"}}>
                    <tbody>
                    <tr>
                        <td><label className={"nameLabel smallText"}>Ad</label></td>
                        <td><input className={"saveText smallText"} type="text" placeholder="Kayıt ismi"
                                   value={saveName}
                                   onChange={(e) => setSaveName(e.target.value)}/></td>
                        <td colSpan={2} style={{textAlign: "center"}}>
                            <FontAwesomeIcon style={{verticalAlign: "middle"}}
                                             className={saveName ? "clickable" : "disabled-clickable"}
                                             icon={["far", "save"]}
                                             onClick={() => {
                                                 if (saveName) {
                                                     props.onSave(saveName);
                                                     setSaveName("");
                                                 }
                                             }}/></td>
                    </tr>
                    {
                        props.savedNames.map((name) =>
                            <tr style={{margin: "auto", fontSize: "11px", padding: "8px"}} key={name} className={"storeRow"}>
                                <td colSpan={2} style={{textAlign: "end"}} className={"itemName"}>
                                    <FontAwesomeIcon icon={["far", "file-code"]} style={{color: "gray"}}/>
                                    {"    " + name}</td>
                                <td><FontAwesomeIcon className={"clickable"} icon={["far", "folder-open"]}
                                                     onClick={() => props.onLoad(name)}/>
                                </td>
                                <td><FontAwesomeIcon className={"dangerous"} icon={["far", "trash-alt"]}
                                                     onClick={() => props.onDelete(name)}/>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
}
