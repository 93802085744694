export const RateSuffix = "_Rate"
export type Currency = "Dollar" | "Euro" | "Lira";
export type CurrencyRate = `${Currency}_Rate`;
export type CurrencyOrRate = Currency | CurrencyRate;

export const toRate = (value: Currency): CurrencyRate => {
    return value + RateSuffix as CurrencyRate;
}

export const toCurrency = (value: CurrencyRate): Currency => {
    return value.substr(value.indexOf(RateSuffix)) as Currency;
}

export const isCurrency = (value: string): value is Currency => {
    return !isRate(value);
}

export const isRate = (value: string): value is CurrencyRate => {
    return value.endsWith(RateSuffix);
}
