import {Form} from "react-bootstrap";
import DatePicker, {registerLocale} from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import tr from 'date-fns/locale/tr';
import {useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

registerLocale('tr', tr)


export interface SalaryEntryProps {
    date?: Date;
    salary?: string;
    onDateChange: (date: Date) => void;
    onSalaryChange: (salary: string, completed?: boolean) => void;
    onRemoveClicked: () => void;
    badSalary: boolean;
    focusDate: boolean;
    focusSalary: boolean;
}

export default function SalaryEntry(props: SalaryEntryProps) {
    const textInput = useRef(null);

    return (
        <>
            <td>
                <DatePicker
                    locale="tr"
                    dateFormat={"yyyy-MM"}
                    selected={props.date}
                    showMonthYearPicker={true}
                    maxDate={new Date()}
                    onChange={(date: Date) => {
                        console.log(textInput);
                        const selected = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
                        props.onDateChange(selected);
                        // @ts-ignore
                        textInput.current.focus();
                        // @ts-ignore
                        textInput.current.select();
                    }}
                />
            </td>
            <td>
                <Form.Control type="text" placeholder=""
                              size="sm"
                              onChange={(e) => {
                                  props.onSalaryChange(e.target.value);
                              }}
                              value={props.salary}
                              style={{
                                  color: props.badSalary ? "red" : undefined,
                                  borderColor: props.badSalary ? "red" : undefined,
                                  textAlign: "end"
                              }}
                              onKeyPress={(e) => {
                                  if (e.code === "Enter") {
                                      // @ts-ignore
                                      textInput.current.select();
                                      // @ts-ignore
                                      props.onSalaryChange(e.target.value, true);
                                  }
                              }}
                              ref={textInput}
                />
            </td>
            <td>
                <FontAwesomeIcon className={"clickable"} icon={["far", "minus-square"]} onClick={props.onRemoveClicked}/>
            </td>
        </>
    )
}
